import { useEffect } from "react"
import BannerComponent from "../components/base/bannerComponent"
import Container from "../components/base/container"
import PageLayout from "../components/base/page-layout"
import { homePage } from "../components/filters/home-filters.module.scss"
import HomeScreen from "../components/home/home-screen"
import { rentSlug } from "../utils/constants"
import { checkUA, getHomeLinks, recordPageView } from "../utils/utility"

const RentLanding = (props) => {
  const { isMobile, allLinks } = props

  useEffect(() => {
    recordPageView("/")
  }, [])

  return (
    <>
      <PageLayout
        className={homePage}
        description={
          "Browse property on Lamudi.pk the best real estate and property website in Pakistan. Search houses, plots, new projects, commercial plots, villas, flats, ready to move apartments for rent and sale in Karachi Lahore Islamabad and all over Pakistan."
        }
        pageTitle="Rent and Buy Properties in Pakistan"
        headTitle="Lamudi.pk Rent and Buy Pakistan Property. Rent Houses, Flats, Apartments , Portions."
        bannerComponent={<BannerComponent isMobile={isMobile} purpose={rentSlug} />}
        bannerComponentPlacement="left"
      >
        <Container>
          <HomeScreen allLinks={allLinks} purpose={rentSlug} isMobile={isMobile} />
        </Container>
      </PageLayout>
    </>
  )
}

export async function getServerSideProps(ctx) {
  let isMobile = checkUA(ctx.req.headers["user-agent"])

  let links = await getHomeLinks(1)

  return {
    props: {
      isMobile,
      allLinks: links,
    },
  }
}

export default RentLanding
